import { styled } from 'styled-components';

import { BorderWidth, Spacing } from '@ninox/ninox-components/theme';

import { Avatar } from '../../Avatar';

export const Content = styled.div`
    display: flex;
    flex-flow: column;
    padding: ${Spacing.x_small};
    gap: ${Spacing.x_small};
`;

export const StyledAvatar = styled(Avatar)`
    &:active [role="img"] {
        transition-duration: 300ms;
        font-variation-settings: 'FILL' 1;
    }

    outline-color: ${({ theme }) => theme.nav.primary.borderColor};

    &:focus-visible {
        outline-offset: ${Spacing.tiny};
        outline-width: ${BorderWidth.tiny};
        outline-color: ${({ theme }) => theme.icon.secondary};
    }
`;
